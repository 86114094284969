import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Footer from './Footer';

import { rhythm } from '../utils/typography';

const Template = props => {
  const { children, location } = props;

  return (
    <div
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <div
        style={{
          minHeight: '100vh',
          overflow: 'hidden',
          display: 'block',
          position: 'relative',
          paddingBottom: rhythm(4),
        }}
      >
        <Header location={location} />
        {children}
        <Footer />
      </div>
    </div>
  );
};

Template.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default Template;
