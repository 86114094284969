import React from 'react';
import get from 'lodash/get';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { rhythm } from '../utils/typography';

const Header = props => {
  const pathname = get(props, 'location.pathname', '/');

  return (
    <div>
      <div style={{ maxWidth: 'inherit', display: 'flex' }}>
        <h4 className="homeNavLink" style={{ marginBottom: rhythm(1.5) }}>
          <Link
            style={{
              boxShadow: 'none',
              textDecoration: 'none',
              color: 'inherit',
              backgroundImage: 'none',
            }}
            to="/"
          >
            {pathname === '/' ? 'Jacob Learned' : 'JL'}
          </Link>
        </h4>

        <a
          style={{
            textDecoration: 'none',
            color: 'black',
            backgroundImage: 'none',
          }}
          href="https://github.com/jacoblearned"
        >
          <FontAwesomeIcon icon={faGithub} size="lg" pull="left" />
        </a>
        <a
          style={{
            textDecoration: 'none',
            color: 'black',
            backgroundImage: 'none',
          }}
          href="https://www.linkedin.com/in/jacob-learned-04129652"
        >
          <FontAwesomeIcon icon={faLinkedin} size="lg" pull="right" />
        </a>
      </div>
    </div>
  );
};

export default Header;
