import favicon16 from '../assets/favicon-16x16.png';
import favicon32 from '../assets/favicon-32x32.png';
import favicon96 from '../assets/favicon-96x96.png';
import appleIcon57 from '../assets/apple-icon-57x57.png';
import appleIcon60 from '../assets/apple-icon-60x60.png';
import appleIcon72 from '../assets/apple-icon-72x72.png';
import appleIcon76 from '../assets/apple-icon-76x76.png';
import appleIcon114 from '../assets/apple-icon-114x114.png';
import appleIcon120 from '../assets/apple-icon-120x120.png';
import appleIcon144 from '../assets/apple-icon-144x144.png';
import appleIcon152 from '../assets/apple-icon-152x152.png';
import appleIcon180 from '../assets/apple-icon-180x180.png';
import androidIcon192 from '../assets/android-icon-192x192.png';
import msIcon144 from '../assets/ms-icon-144x144.png';

export { msIcon144 };

export default [
  { rel: 'icon', type: 'image/png', href: `${favicon16}` },
  { rel: 'icon', type: 'image/png', href: `${favicon32}` },
  { rel: 'icon', type: 'image/png', href: `${favicon96}` },
  {
    rel: 'apple-touch-icon',
    type: 'image/png',
    href: `${appleIcon57}`,
  },
  {
    rel: 'apple-touch-icon',
    type: 'image/png',
    href: `${appleIcon60}`,
  },
  {
    rel: 'apple-touch-icon',
    type: 'image/png',
    href: `${appleIcon72}`,
  },
  {
    rel: 'apple-touch-icon',
    type: 'image/png',
    href: `${appleIcon76}`,
  },
  {
    rel: 'apple-touch-icon',
    type: 'image/png',
    href: `${appleIcon114}`,
  },
  {
    rel: 'apple-touch-icon',
    type: 'image/png',
    href: `${appleIcon120}`,
  },
  {
    rel: 'apple-touch-icon',
    type: 'image/png',
    href: `${appleIcon144}`,
  },
  {
    rel: 'apple-touch-icon',
    type: 'image/png',
    href: `${appleIcon152}`,
  },
  {
    rel: 'apple-touch-icon',
    type: 'image/png',
    href: `${appleIcon180}`,
  },
  { rel: 'icon', type: 'image/png', href: `${androidIcon192}` },
];
