import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';

import Bio from '../components/Bio';
import Layout from '../components/layout';
import { rhythm } from '../utils/typography';
import icons, { msIcon144 } from '../utils/favicons';

const BlogIndex = props => {
  const { location } = props;
  const siteTitle = get(props, 'data.site.siteMetadata.title');
  const siteDescription = get(props, 'data.site.siteMetadata.description');
  const posts = []; // get(props, 'data.allMarkdownRemark.edges');

  return (
    <Layout location={location}>
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        title={siteTitle}
        meta={[
          { name: 'description', content: siteDescription },
          { name: 'msapplication-TileColor', content: '#ffffff' },
          { name: 'msapplication-TileImage', content: `${msIcon144}` },
        ]}
        link={icons}
      />

      <Bio />

      {posts.map(({ node }) => {
        const title = get(node, 'frontmatter.title') || node.fields.slug;
        return (
          <div key={node.fields.slug}>
            <h3
              style={{
                marginBottom: rhythm(1 / 4),
              }}
            >
              <Link className="postLink" to={node.fields.slug}>
                {title}
              </Link>
            </h3>
            <small>{node.frontmatter.date}</small>
            {/* eslint-disable-next-line react/no-danger */}
            <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
          </div>
        );
      })}
    </Layout>
  );
};

BlogIndex.propTypes = {
  location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
          }
        }
      }
    }
  }
`;
