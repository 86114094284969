import React from 'react';
import { rhythm } from '../utils/typography';

const Bio = () => (
  <div
    style={{
      minHeight: rhythm(2),
      marginTop: rhythm(4),
      position: 'absolute',
      bottom: 0,
      backgroundColor: 'white',
      borderTop: '1px black solid',
    }}
  >
    <p
      style={{
        maxHeight: rhythm(3),
        marginBottom: '0px',
        fontSize: rhythm(1 / 2),
        paddingTop: '1rem',
      }}
    >
      All views and opinions expressed on this site are my own and do not
      represent the opinions of any entity whatsoever with which I have been, am
      now, or will be affiliated with or employed by.
    </p>
  </div>
);

export default Bio;
