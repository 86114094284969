import React from 'react';
import { rhythm } from '../utils/typography';

const Bio = () => (
  <div
    style={{
      minHeight: rhythm(3.5),
      marginBottom: rhythm(2.33),
    }}
  >
    <p
      style={{
        marginBottom: '0px',
        display: 'inline',
        fontSize: rhythm(3 / 5),
      }}
    >
      I&#39;m a software engineer at
      <br />
      Capital One Labs focused on
      <br />
      developer experience.
    </p>
  </div>
);

export default Bio;
